exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-index-mdx": () => import("./../../../src/pages/about-me/index.mdx" /* webpackChunkName: "component---src-pages-about-me-index-mdx" */),
  "component---src-pages-base-srd-index-mdx": () => import("./../../../src/pages/base-srd/index.mdx" /* webpackChunkName: "component---src-pages-base-srd-index-mdx" */),
  "component---src-pages-blog-custom-fonts-with-unity-3-d-mdx": () => import("./../../../src/pages/blog/custom-fonts-with-unity-3d.mdx" /* webpackChunkName: "component---src-pages-blog-custom-fonts-with-unity-3-d-mdx" */),
  "component---src-pages-blog-fun-with-types-units-of-measure-mdx": () => import("./../../../src/pages/blog/fun-with-types-units-of-measure.mdx" /* webpackChunkName: "component---src-pages-blog-fun-with-types-units-of-measure-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-magic-index-mdx": () => import("./../../../src/pages/magic/index.mdx" /* webpackChunkName: "component---src-pages-magic-index-mdx" */),
  "component---src-pages-resume-index-mdx": () => import("./../../../src/pages/resume/index.mdx" /* webpackChunkName: "component---src-pages-resume-index-mdx" */),
  "component---src-pages-srd-index-mdx": () => import("./../../../src/pages/srd/index.mdx" /* webpackChunkName: "component---src-pages-srd-index-mdx" */)
}

